.carousel-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel-slide {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-image {
  max-width: 100%; /* Ensure image doesn't overflow container */
  max-height: 100%; /* Ensure image doesn't overflow container */
  object-fit: cover;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 36px;
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  outline: none;
  z-index: 1;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) {
  .carousel-container {
    height: 300px; /* Example height for small screens */
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .carousel-container {
    height: 400px; /* Example height for medium screens */
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .carousel-container {
    height: 500px; /* Example height for large screens */
  }
}